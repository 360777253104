import {Routes} from "@angular/router";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {CtModelComponent, CtModelType} from "@ctsolution/ct-framework";
import {ContractModule} from "./contract/contract.module";
import {CustomerModule} from "./customer/customer.module";
import {WarrantyModule} from "./warranty/warranty.module";

export const PagesRoutes: Routes = [
    {
        path: "",
        children: [
            {
                path: "dashboard",
                component: DashboardComponent,
                data: {
                    title: "La tua dashboard",
                    urls: [{title: "Dashboard"}]
                }
            },
            {
                path: "contract",
                loadChildren: () => ContractModule
            },
            {
                path: "customer",
                loadChildren: () => CustomerModule
            },
            {
                path: "warranty",
                loadChildren: () => WarrantyModule
            },
            {
                path: "ct-model",
                children: [
                    {
                        path: ":controller",
                        children: [
                            {
                                path: "",
                                redirectTo: "list",
                                pathMatch: "full"
                            },
                            {
                                path: "create",
                                component: CtModelComponent,
                                data: {type: CtModelType.FORM}
                            },
                            {
                                path: "edit/:id",
                                component: CtModelComponent,
                                data: {type: CtModelType.FORM}
                            },
                            {
                                path: "calendar",
                                component: CtModelComponent,
                                data: {
                                    type: CtModelType.CALENDAR
                                }
                            },
                            {
                                path: "list",
                                component: CtModelComponent
                            },
                            {
                                path: "**",
                                redirectTo: "list",
                                pathMatch: "full"
                            }
                        ]
                    },
                ]
            },
            {
                path: "",
                redirectTo: "dashboard",
                pathMatch: "full"
            }
        ]
    }
];
